import React, { useRef } from 'react'
import './contact.css'
import { BsLinkedin, BsGithub } from 'react-icons/bs'
import { SiGmail } from 'react-icons/si'
import emailjs from 'emailjs-com'

function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_s2dr665', 'template_49qeytd', form.current, '5D7cU_NCVLzkBe6mY')
            .then((result) => {
                e.target.reset();
                alert("Message sucessfully sent, thanks for stopping by!")
            }, (error) => {
                console.log(error.text);
            });
    }
    return (
        <section id='contact'>
            <div className="bg__title">
                <h5>I'd love to hear from you!</h5>
                <h2>Contact me</h2>
            </div>

            <div className="container contact__container">
                <div className="contact__options">
                    <article className='contact__option'>
                        <SiGmail className='contact__option-icon' />
                        <h4>Email</h4>
                        <h5>fullstackamber@gmail.com</h5>
                        <a href="mailto:fullstackamber@gmail.com" className='btn btn-primary'>Send a message</a>
                    </article>

                    <article className='contact__option'>
                        < BsLinkedin className='contact__option-icon' />
                        <h4>LinkedIn</h4>
                        <h5>https://www.linkedin.com/in/amber-robeck/</h5>
                        <a href="https://www.linkedin.com/in/amber-robeck/" target='_blank' className='btn btn-primary'>Connect with me</a>
                    </article>

                    <article className='contact__option'>
                        < BsGithub className='contact__option-icon' />
                        <h4>GitHub</h4>
                        <h5>https://github.com/Amber-Robeck</h5>
                        <a href="https://github.com/Amber-Robeck" target='_blank' className='btn btn-primary'>Collaborate with me</a>
                    </article>
                </div>
                <form ref={form} onSubmit={sendEmail}>
                    <input type='text' name='name' placeholder='Your name' required />
                    <input type='email' name='email' placeholder='Email' required />
                    <textarea name='message' rows='7' placeholder='Your message' required />
                    <button type='submit' className='btn btn-primary'>Send message</button>
                </form>
            </div>
        </section>
    )
}

export default Contact
