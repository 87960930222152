import React from 'react'
import './skills.css'
import { SiHtml5, SiCss3, SiJavascript, SiBootstrap, SiReact, SiNodedotjs, SiMongodb, SiMysql, SiInsomnia, SiJava, SiTypescript, SiExpress } from 'react-icons/si'

const Skills = () => {
    return (
        <section id='skills'>
            <div className="bg__title">
                <h5>My favorite tools</h5>
                <h2>My skills</h2>
            </div>

            <div className="container skills__container">
                <div className="skills__frontend">
                    <h3>Frontend Development</h3>
                    <div className="skills__content">
                        <article className='skills__details'>
                            <SiHtml5 className='skills__details-icons' />
                            <div>
                                <h4>HTML</h4>
                            </div>
                        </article>
                        <article className='skills__details'>
                            <SiCss3 className='skills__details-icons' />
                            <div>
                                <h4>CSS</h4>
                            </div>
                        </article>
                        <article className='skills__details'>
                            <SiJavascript className='skills__details-icons' />
                            <div>
                                <h4>JavaScript</h4>
                            </div>
                        </article>
                        <article className='skills__details'>
                            <SiBootstrap className='skills__details-icons' />
                            <div>
                                <h4>Bootstrap</h4>
                            </div>
                        </article>
                        <article className='skills__details'>
                            <SiReact className='skills__details-icons' />
                            <div>
                                <h4>React</h4>
                            </div>
                        </article>
                        <article className='skills__details'>
                            <SiTypescript className='skills__details-icons' />
                            <div>
                                <h4>Typescript</h4>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="skills__backend">
                    <h3>Backend Development</h3>
                    <div className="skills__content">
                        <article className='skills__details'>
                            <SiNodedotjs className='skills__details-icons' />
                            <div>
                                <h4>NodeJs</h4>
                            </div>
                        </article>
                        <article className='skills__details'>
                            <SiMongodb className='skills__details-icons' />
                            <div>
                                <h4>MongoDb</h4>
                            </div>
                        </article>
                        <article className='skills__details'>
                            <SiMysql className='skills__details-icons' />
                            <div>
                                <h4>MySql</h4>
                            </div>
                        </article>
                        <article className='skills__details'>
                            <SiInsomnia className='skills__details-icons' />
                            <div>
                                <h4>Insomnia</h4>
                            </div>
                        </article>
                        <article className='skills__details'>
                            <SiJava className='skills__details-icons' />
                            <div>
                                <h4>Java</h4>
                            </div>
                        </article>
                        <article className='skills__details'>
                            <SiExpress className='skills__details-icons' />
                            <div>
                                <h4>Express</h4>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skills
