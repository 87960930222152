import React from 'react'
import './about.css'
import IMAGE from '../../assets/coming-soon.png'
import { FaAward } from 'react-icons/fa'
import { GrTroubleshoot } from 'react-icons/gr'
import { VscDebugConsole } from 'react-icons/vsc'
import { BsLightbulb } from 'react-icons/bs'
import { AiOutlineLaptop } from 'react-icons/ai'
import FISH from '../../assets/fish.png'

const About = () => {
    return (
        <section id='about'>
            <div className="bg__title">
                <h5>Get to know</h5>
                <h2>About me</h2>
            </div>

            <div className="container about__container">
                <div className="about__me">
                    <img src={FISH} alt="coming soon" />
                </div>

                <div className="about__content">
                    <div className="about__cards">
                        <article className='about__card'>
                            <AiOutlineLaptop className='about__icon' />
                            <h5>Development</h5>
                            <small>Helping you deliver the content you need when and where you need it.</small>
                        </article>

                        <article className='about__card'>
                            <BsLightbulb className='about__icon' />
                            <h5>Creative thinking</h5>
                            <small>Finding new ways to tackle tough tasks with your projects goals in mind.</small>
                        </article>

                        <article className='about__card'>
                            <VscDebugConsole className='about__icon' />
                            <h5>Problem-solving</h5>
                            <small>Implementing sustainable solutions to everyday problems.</small>
                        </article>
                    </div>

                    <p className='about__card'>How does one begin an about me section? Once upon a time, nope that's not it! How about
                        hi? Hi, my name is Amber and I'm happy you stopped by my page. My favorite hobby is fishing, no matter
                        the time of year I always enjoy getting out. Professionally I have had many titles from entry-level to
                        supervisor roles, in many different fields. All of the positions I have had, they all share something in
                        common that I truly enjoy-troubleshooting! From 'why doesn't my car start' to 'the thickness of this
                        part is out of specification' I enjoy the sense of accomplishment after a hard day's work. I am
                        consistently finding ways to further my knowledge on well, anything, currently studying every day to keep up with this rapidly changing industry. Thanks again for stopping by and I
                        look forward to meeting and working with you in the near future!</p>
                    <a href="#contact" className='btn btn-primary'>Chat?</a>
                </div>
            </div>
        </section>
    )
}

export default About
