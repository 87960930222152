import React from 'react'

import Header from './components/header/Header'
import Navigation from './components/navigation/Navigation'
import About from './components/about/About'
import Skills from './components/skills/Skills'
import Portfolio from './components/portfolio/Portfolio'
import Recommendations from './components/recommendations/Recommendations'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'

import './index.css'



const App = () => {
    return (
        <>
            <Header />
            <Navigation />
            <About />
            <Skills />
            <Portfolio />
            <Recommendations />
            <Contact />
            <Footer />
        </>
    )
}

export default App
