import React from 'react'
import RESUME from '../../assets/resume.pdf'

const CTA = () => {
    return (
        <div className='cta'>
            <a href={RESUME} download className='btn'>Download resume</a>
            <a href="#contact" className='btn btn-primary'>Let's Chat</a>

        </div>
    )
}

export default CTA