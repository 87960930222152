import React from 'react'
import './recommendations.css'
import IMG from '../../assets/coming-soon.png'

// import Swiper core and required modules
import { Pagination, Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


const data = [
    {
        avatar: 'https://media-exp1.licdn.com/dms/image/C4E03AQHU_nh2Je1cRQ/profile-displayphoto-shrink_100_100/0/1641585552777?e=2147483647&v=beta&t=Sw7jNAMqkNKKMmATTDw1Ubl-vJrT4sQw1INopNQtbJA',
        name: 'Jae Ahn',
        review: "I had the pleasure of working with Amber for three months while we were both enrolled in the U of MN coding bootcamp. During this time, Amber was an immense resource for me and all of the other students in our bootcamp. She has a true passion for coding and problem-solving, which was evident in the way that she would happily take the time to help debug others' code or answer questions they may have. I was also fortunate enough to work in a team with Amber for our final project, which she had an immense role in creating. Amber's greatest strengths are her constant drive to improve her coding skills/knowledge, her excellent communication skills, and sense of humor. I believe Amber is an invaluable asset for any team or employer she works with/for, and I have no doubt that she will excel wherever her career takes her. I look forward to following her progress and working with her on future projects."
    },
    {
        avatar: 'https://media.licdn.com/dms/image/C4D03AQE-X3CdVreAHA/profile-displayphoto-shrink_100_100/0/1641526543445?e=1680739200&v=beta&t=jM3lVB3l2Pwe8U1K7roEN2_5aG6H1SQXC6uuhktHczA',
        name: 'Hunter Coleman',
        review: "Amber was a great partner to have in our group for our final project. She is very easy to communicate with and is always willing to help out anyone that needs it. Like when we were going through classes and a student was called and didn't know the answer they'd almost always popcorn Amber. Either because she knew the answer or because everyone knew they could call on her comfortable. since she was so easy to communicate with. She also did a great job contributing to our project, I would totally recommend her for any team."
    },
    {
        avatar: 'https://media.licdn.com/dms/image/D5635AQEulodNVd3Qhg/profile-framedphoto-shrink_100_100/0/1673991547002?e=1675897200&v=beta&t=VIYZQJzG6HH8P1Wrrag_YxVitWKlEgyu_C2U02DnJS4',
        name: 'Eric DeWald',
        review: 'Amber is a great developer and I was lucky to work with her on our project, as well as several assignments. It was humbling to watch her work, and determination meet the technical demands of being a great developer and teammate. I have no doubt that Amber will achieve great things in the workplace and as she continues to grow. In addition, I found her always willing to help in order to solve difficult problems for others, and is a great teammate. From my experience working with her, I believe any team would be lucky to have her.'
    },
    {
        avatar: 'https://media.licdn.com/dms/image/D4E35AQGu0EqvF6GXQw/profile-framedphoto-shrink_100_100/0/1642734005184?e=1675897200&v=beta&t=rpRM1VvpqA6sy9X-mUdIlmFXqffeJQ_IKtkf1hPtx_Q',
        name: 'Tyler Bantley',
        review: 'Amber was a phenomenal partner to have on our 1st big project. I think we were both equally as nervous at first but we snapped out of it and built a great application together that received great reviews from our instructors and peers. She worked very hard, was very good with communication and NEVER gave up on any problems that arose. In the future I could definitely see her running a project or a team as she had wonderful leadership skills and out of everyone in our class, she was the MOST helpful when it came to anything you had a question about. A++++ for her and I look forward to maybe collaborating in the future!'
    }
]

const Recommendations = () => {
    return (
        <section id='recommendations'>
            <div className="bg__title">
                <h5>What are people saying?</h5>
                <h2>Recommendations</h2>
            </div>

            <Swiper className="container recommendations__container"
                // install Swiper modules
                modules={[Navigation, Pagination]}
                spaceBetween={40}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
            >
                {
                    data.map(({ avatar, name, review }, index) => {
                        return (
                            <SwiperSlide key={index} className='recommendation'>
                                <div className="rec__avatar">
                                    <img src={avatar} alt="LinkedinPic" />
                                </div>
                                <h5 className='rec__name'>{name}</h5>
                                <small className='rec__review'>{review}</small>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </section>
    )
}

export default Recommendations
