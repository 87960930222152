import React from 'react'
import './portfolio.css'
import IMG from '../../assets/coming-soon.png'
import CHORDBASE from '../../assets/chordbase.png'
import CATMATCH from '../../assets/catMatch.png'
import DOLLARS from '../../assets/dollars.png'
import ECOM from '../../assets/categoryget.png'
import EMPLOYEE from '../../assets/employee.png'


const data = [
    {
        id: 1,
        image: DOLLARS,
        title: 'Dollars & Bills',
        desc: ' Dollars and Bills was created to solve the age-old problem of keeping track of your dollars whilst paying your bills. As a user you are allowed to create a budget, expenses within and then update or delete them.',
        tools: ' React, GraphQL, React-Bootstrap, React-chartjs-2',
        code: 'https://github.com/Amber-Robeck/Dollars_and_Bills',
        live: 'https://dollarsandbills.onrender.com/'
    },
    {
        id: 2,
        image: CATMATCH,
        title: 'Match-O-Matic',
        desc: ' Friendly matching game where a user can pick what types of pictures they would like to match from multiple different APIs/datasets. Currently this application is set up to retrieve data from the DOG API as well as the CATS AS A SERVICE (CATAAS) API.',
        tools: ' Vanilla javascript, CSS, DOG API, CATAAS API',
        code: 'https://github.com/Amber-Robeck/matchOmatic',
        live: 'https://amber-robeck.github.io/matchOmatic/'
    },
    {
        id: 3,
        image: CHORDBASE,
        title: 'Chord Base',
        desc: 'Do you want to learn the guitar? Why pay 20 dollars an hour for an instructor when we have a great free app for you. The point of this application is to help teach someone the basics of guitar and playing guitar chords. ',
        tools: ' HTML, CSS, Javascript, APIs, Bulma',
        code: 'https://github.com/Amber-Robeck/Chord-Base',
        live: 'https://amber-robeck.github.io/Chord-Base/'
    },
    {
        id: 4,
        image: ECOM,
        title: 'Ecommerce Backend',
        desc: 'This application is ran from the back-end and does not have a deployed page however, it can be ran on the command line and database changed through insomnia and is a fantastic way to save your store or businesses inventory!',
        tools: ' Javascript, Express, MYSQL, Sequelize',
        code: 'https://github.com/Amber-Robeck/eCommerceBackEnd',
        // live: 'https://github.com/Amber-Robeck/eCommerceBackEnd'
    },
    {
        id: 5,
        image: EMPLOYEE,
        title: 'Employee Tracker',
        desc: "CLI for employee management database. This application is ran from the back-end and does not have a deployed page however, it can be ran on the command line!.",
        tools: ' Node.js, Npm, Inquirer, MYSQL, Console.table',
        code: 'https://github.com/Amber-Robeck/employeeTracker',
        // live: 'https://github.com/Amber-Robeck/employeeTracker'
    },
    {
        id: 6,
        image: IMG,
        title: 'Reeler',
        desc: 'A social media site for people who enjoy fishing. This site is currently under development, tune back in later to see the project.',
        tools: ' React, Typescript..more soon',
        code: 'https://github.com/Amber-Robeck/reeler',
        // live: 'www.github.com'
    }
]

const Portfolio = () => {
    return (
        <section id='portfolio'>
            <div className="bg__title">
                <h5>My recent work</h5>
                <h2>Portfolio</h2>
            </div>
            <div className="container portfolio__container">
                {
                    data.map(({ id, image, title, desc, tools, code, live }) => {
                        return (
                            <article className='portfolio__item'>
                                <div className="portfolio__item-image">
                                    <h3>{title}</h3>
                                    <img src={image} alt={title} />
                                    <h4>{desc}</h4>
                                    <h4><span className="bolder">Technologies used:</span>{tools}</h4>
                                    <div className="portfolio__item-cta">
                                        <a href={code} target='_blank' className='btn'>Github</a>
                                        {live ? (<a href={live} target='_blank' className='btn btn-primary'>Live link</a>) : (<p>Sorry no live link at this time</p>)}
                                    </div>
                                </div>
                            </article>
                        )
                    })
                }
            </div >
        </section >
    )
}

export default Portfolio
