import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/avatar.png'
import Socials from './Socials'
import { BsBoxArrowInRight } from 'react-icons/bs'

const Header = () => {
    return (
        <header id='home'>
            <div className="container header__container">
                <h5>Hi there, I'm</h5>
                <h1>Amber Robeck</h1>
                <h5 className='text-light'>Freelance problem-solver</h5>

                <CTA />
                <Socials />

                <div className="me">
                    <img src={ME} alt="Avatar of Amber" />
                </div>

                <a href="#contact" className='scroll__down'>Let's get in touch<BsBoxArrowInRight /></a>
            </div>


        </header>
    )
}

export default Header
