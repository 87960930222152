import React from 'react'
import './navigation.css'
import { BiHomeSmile, BiUserCircle, BiMessageDetail } from 'react-icons/bi'
import { GiBurningBook } from 'react-icons/gi'
import { FaHandsHelping, FaSwatchbook } from 'react-icons/fa'
import { useState } from 'react'

const Navigation = () => {
    const [activeIcon, setActiveIcon] = useState('#home')
    return (
        <nav>
            <a href="#home" onClick={() => setActiveIcon('#home')} className={activeIcon === '#home' ? 'active' : ''}><BiHomeSmile /></a>
            <a href="#about" onClick={() => setActiveIcon('#about')} className={activeIcon === '#about' ? 'active' : ''}><BiUserCircle /></a>
            <a href="#skills" onClick={() => setActiveIcon('#skills')} className={activeIcon === '#skills' ? 'active' : ''}><GiBurningBook /></a>
            <a href="#portfolio" onClick={() => setActiveIcon('#portfolio')} className={activeIcon === '#portfolio' ? 'active' : ''}><FaSwatchbook /></a>
            <a href="#contact" onClick={() => setActiveIcon('#contact')} className={activeIcon === '#contact' ? 'active' : ''}><BiMessageDetail /></a>
        </nav>
    )
}

export default Navigation
