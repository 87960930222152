import React from 'react'
import { BsLinkedin, BsGithub } from 'react-icons/bs'
import { SiGmail } from 'react-icons/si'


const Socials = () => {
    return (
        <div className='socials'>
            <a href="https://www.linkedin.com/in/amber-robeck/" target='_blank'><BsLinkedin className='socials__icon' /></a>
            <a href="https://github.com/Amber-Robeck" target='_blank'><BsGithub className='socials__icon' /></a>
            <a href="mailto:fullstackamber@gmail.com" target='_blank'><SiGmail className='socials__icon' /></a>

        </div>
    )
}

export default Socials
